<template>
    <div class="modal-profile-item-selector">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">close</i>
            <span class="f-18 m-l-12 c-black">결제</span>
        </div>
        <div class="container">
            <div class="header">
                <p>결제가 완료되어야</p>
                <p>약속 확정이 가능합니다!</p>
            </div>
            <div class="desc m-b-16">
                남성분의 결제가 진행되어야 여성분이 결제할 수 있습니다. 결제가 늦어지면 좋은 인연을 놓칠 수 있습니다.
            </div>
            <div>
                <div class="f-16 c-black m-b-8">확정 일정</div>
                <div class="grey-box m-b-32">
                    <div class="user-profile">
                        <img :src="userInfo.photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="userInfo.age" />
                            <div class="region" v-html="time" />
                            <div class="meet-type" v-html="meetType" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons-container">
                <BottomButton @click="onClickPay" class="m-b-8" :non-fixed="true" label="지금 결제" />
                <BottomButton @click="onClickClose" class="white" :non-fixed="true" label="나중에 결제" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalPayTicket',
    props: ['options'],
    methods: {
        onClickClose() {
            this.$emit('close')
        },
        onClickPay() {
            this.$stackRouter.push({
                name: 'SchedulePayPage',
                props: {
                    userInfo: this.userInfo,
                },
            })

            setTimeout(() => this.onClickClose(), 200)
        },
    },
    computed: {
        userInfo() {
            return this.options.userInfo
        },
        time() {
            const dateConfirmed = this.userInfo.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']
            const meetPlace = this.userInfo.meet_place

            return fixed.includes(this.userInfo.meet_type_confirmed)
                ? `${meetPlace}에서 ${this.$translate(this.userInfo.meet_type_confirmed.toUpperCase())} 예정`
                : `${meetPlace}에서 커피 또는 식사 예정`
        },
        openNextDay() {
            const today = this.$moment().add(1, 'days')
            const day = this.$moment(this.userInfo.date_confirmed)
            return this.$moment(today).format('YY/MM/DD') === this.$moment(day).format('YY/MM/DD')
        },
    },
    mounted() {
        if (this.openNextDay) {
            this.$modal.basic({
                title: '선택하신 약속이 내일이네요!',
                body: '약속 확정시 바로 방이 생성됩니다.',
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
        }
    },
}
</script>

<style scoped lang="scss">
.modal-profile-item-selector {
    .container {
        padding: 0 16px !important;
    }

    .header {
        color: black;
        font-size: 18px;
        text-align: center;
        line-height: 28px;
        margin: 24px 0 12px 0;
        @include spoqa-f-bold;
    }

    .desc {
        font-size: 14px;
        line-height: 24px;
    }

    .grey-box {
        background: $grey-01;
        padding: 8px;
        font-size: 14px;
        border-radius: 12px;

        .user-profile {
            display: flex;
            align-items: center;
            color: black;
            font-size: 14px;
            line-height: 24px;

            img {
                width: 80px;
                height: 80px;
                border: 1px solid white;
                border-radius: 8px;
                margin-right: 14px;
            }
        }
    }

    ::v-deep .bottom-button {
        &.white > button {
            background: white !important;
            border: 1px solid $grey-06;
            color: $grey-09 !important;
        }
    }
}
</style>
